<template>
  <div v-if="fetch_data">
    <div id="container" class="index">
      <vue-editor v-show="false"/> <!-- dummy -->
      <TopSlider :carousels="fetch_data.carousels"/>

      <!-- content overview -->
      <ContentOverview :spaces="overview"/>

      <!-- category 'content' posts list  -->
      <CategoryPosts :category="'content'" :tags="fetch_data.tags" />

      <!-- online shop  -->
      <OnlineShop v-if="false"/>

      <!-- recommend follow -->
      <FollowRecommend v-if="false"/>

      <!-- category 'social' posts list  -->
      <CategoryPosts :category="'social'" :tags="fetch_data.tags" />

      <!-- category 'timeline' posts list  -->
      <CategoryPosts :category="'timeline'" :tags="fetch_data.tags" />

      <!-- category 'media' posts list  -->
      <CategoryPosts :category="'media'" :tags="fetch_data.tags" />

      <!-- category 'notice' posts list  -->
      <CategoryPosts :category="'notice'" :tags="fetch_data.tags" />

      <!-- today's events -->
      <EventToday :today="fetch_data.today" />

      <!-- event list  -->
      <EventList :events="fetch_data.events"/>

      <!-- category 'workshop' posts list  -->
      <CategoryPosts :category="'workshop'" :tags="fetch_data.tags" />

      <!-- how to -->
      <HowToList :how_to_list="fetch_data.how_to_list" />

      <!-- activities  -->
      <Activities/>

      <!-- banner list  -->
      <section id="top_bnr_area" v-html="fetch_data.banner_html" />
      <Banners v-if="false"/>

      <!-- recommend content -->
      <ContentRecommend v-if="false" :posts="fetch_data.recommends"/>

      <!-- work shop list  -->
      <WorkshopList v-if="false"/>
    </div>
  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import TopSlider from '@/views/users/customer/elements/top/_top_slider'
import EventToday from '@/views/users/customer/elements/top/_event_today'
import EventList from '@/views/users/customer/elements/top/_event_list'
import ContentOverview from '@/views/users/customer/elements/top/_content_overview'
import CategoryPosts from '@/views/users/customer/elements/top/_category_posts'
import ContentRecommend from '@/views/users/customer/elements/top/_content_recomend'
import HowToList from '@/views/users/customer/elements/top/_how_to_list'
import WorkshopList from '@/views/users/customer/elements/top/_workshop_list'
import OnlineShop from '@/views/users/customer/elements/top/_online_shop'
import FollowRecommend from '@/views/users/customer/elements/top/_follow_recomend'
import Activities from '@/views/users/customer/elements/top/_activities'
import Banners from '@/views/users/customer/elements/top/_banners'

import {VueEditor} from "vue2-editor";

export default {
  mixins: [Libraries],
  components: {
    TopSlider,
    EventToday,
    EventList,
    ContentOverview,
    CategoryPosts,
    ContentRecommend,
    HowToList,
    WorkshopList,
    OnlineShop,
    FollowRecommend,
    Activities,
    Banners,

    VueEditor
  },
  data() {
    return {
      fetch_data: null,
      overview: [],
    }
  },

  created() {
  },
  mounted() {
    // 休塾中ならマイページへ
    if (this.isUserSleep()) {
      this.$router.push({name: 'UsersCustomerAccount'})
      return
    }
    this.fetchData()
  },

  watch: {
    $route(/*to, from*/) {
      if (location.hash) {
        setTimeout(() => {
          //this.scrollTo(location.hash)
          this.$scrollTo(location.hash, 0, {offset: -80})
        }, 300)
      }
    }
  },

  methods: {
    fetchData() {
      this.scrollTop()
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}users/top.json`, {
            params: {
              draft: this.$route.query.draft
            },
          })
          .then(response => {
            this.fetch_data = response.data
            this.saveCacheStore(this.$route.name, this.fetch_data)
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.fetchDone()
            this.finishAjax()
            if (location.hash) {
              setTimeout(() => {
                this.scrollTo(location.hash)
              }, 200)
            }
          })
      this.axios
          .get(`${this.env.api_base_url}users/category/overview.json`)
          .then(response => this.overview = response.data)
          .catch(response => this.errorAjax(response))
    },
    fetchDone() {
      // user js への onload処理
      if (typeof _million_onload !== 'undefined') {
        window._million_onload(this.fetch_data.uri);
      }

      const slide_main = window.$(".slide").slick({
        asNavFor: ".slide-navigation",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4500,
        speed: 600,
        arrows: true,
        fade: true,
        responsive: [
          {
            breakpoint: 768, // 399px以下のサイズに適用
            settings: {
              asNavFor: false,
              dots: true,
            },
          },
        ],
      });
      const slide_sub = window.$(".slide-navigation").slick({
        asNavFor: ".slide",
        accessibility: true,
        centerMode: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4500,
        speed: 600,
        focusOnSelect: true,
      });
      const open_window_Width = window.$(window).width();
      window.$(window).resize(function() {
        var width = window.$(window).width();
        if (open_window_Width != width) {
          slide_main.slick("setPosition");
          slide_sub.slick("setPosition");
        }
      });



      /*
      // accordion menu
      window.$('.index_menu .index_wrap').hide()
      window.$('#ovr').css({'top': 0, 'display': 'none'})
      window.$('.menu_tab').on('click', function () {
        if (!window.$(this).parent('.index_menu').hasClass('open')) {
          window.$(this).prev('.index_wrap').slideToggle()
          window.$(this).parent('.index_menu').toggleClass('open')
          window.$('#ovr').fadeIn(200).css({'top': 0})
        } else {
          window.$(this).prev('.index_wrap').slideToggle()
          window.$(this).parent('.index_menu').toggleClass('open');
          window.$('#ovr').fadeOut(200).css({'top': 0})
        }
      })

      // accordion contents
      window.$('.acc_tab').next('.acc_cts').hide();
      window.$('.acc_tab.open').next('.acc_cts').show();
      window.$('.acc_menu .acc_tab').on('click', function () {
        window.$(this).next('.acc_cts').slideToggle();
        window.$(this).toggleClass('open');
      })
       */

      /*
      const slide_main = window.$(".slide").slick({
        asNavFor: ".slide-navigation",
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
      });
      const slide_sub = window.$(".slide-navigation").slick({
        asNavFor: ".slide",
        centerMode: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        speed: 400,
        focusOnSelect: true,
      });

      const open_window_Width = window.$(window).width();
      window.$(window).resize(function () {
        const width = window.$(window).width();
        if (open_window_Width != width) {
          slide_main.slick("setPosition");
          slide_sub.slick("setPosition");
        }
      });
     */
    },
  }
}
</script>
