<template>
  <section id="sns_follow" class="top_cts acc_menu">
    <div class="wrap">
      <h2 class="sub_ttl acc_tab" v-bind:class="{'open': open}"><span>フォローのおすすめ TODO:close</span></h2>
      <div class="contents acc_cts" style="display: block;">
        <p class="top_lead_txt">あなたと共通する話題をお持ちの塾生です。こちらの方々もフォローしてみてはいかがでしょうか？</p>
        <div class="follow_list flex">
          <div class="follow_box">
            <div class="icon"><a href=""><img src="/common/images/sample/top_follower01.jpg" alt=""></a></div>
            <h3>山田 太郎</h3>
            <a href="" class="btn_follow">フォローする</a>
          </div>
          <div class="follow_box follow">
            <div class="icon"><a href=""><img src="/common/images/sample/top_follower02.jpg" alt=""></a></div>
            <h3>山田 太郎</h3>
            <a href="" class="btn_follow">フォロー解除</a>
          </div>
          <div class="follow_box">
            <div class="icon"><a href=""><img src="/common/images/sample/top_follower03.jpg" alt=""></a></div>
            <h3>山田 太郎</h3>
            <a href="" class="btn_follow">フォローする</a>
          </div>
          <div class="follow_box follow">
            <div class="icon"><a href=""><img src="/common/images/sample/top_follower04.jpg" alt=""></a></div>
            <h3>山田 太郎</h3>
            <a href="" class="btn_follow">フォロー解除</a>
          </div>
          <div class="follow_box">
            <div class="icon"><a href=""><img src="/common/images/sample/top_follower05.jpg" alt=""></a></div>
            <h3>山田山田 太郎太郎太郎</h3>
            <a href="" class="btn_follow">フォローする</a>
          </div>
          <div class="follow_box follow">
            <div class="icon"><a href=""><img src="/common/images/sample/top_follower06.jpg" alt=""></a></div>
            <h3>山田 太郎</h3>
            <a href="" class="btn_follow">フォロー解除</a>
          </div>
          <div class="follow_box">
            <div class="icon"><a href=""><img src="/common/images/sample/top_follower07.jpg" alt=""></a></div>
            <h3>山田 太郎</h3>
            <a href="" class="btn_follow">フォローする</a>
          </div>
          <div class="follow_box follow">
            <div class="icon"><a href=""><img src="/common/images/sample/top_follower08.jpg" alt=""></a></div>
            <h3>山田 太郎</h3>
            <a href="" class="btn_follow">フォロー解除</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    open: null
  }
}
</script>
