<template>
  <section id="top_slider">
    <div class="slide-box">
      <ul class="slide">
        <li class="item" v-for="carousel in carousels" :key="carousel.id">
          <a v-bind:href="carousel.url" v-bind:target="'_' + carousel.target">
            <img v-bind:src="carousel.pc_image_file_url" alt="" class="pc">
            <img v-bind:src="carousel.sp_image_file_url" alt="" class="sp">
          </a>
        </li>
      </ul>
      <div class="slide-navigation">
        <div class="item" v-for="carousel in carousels" :key="carousel.id">
          <figure class="image">
            <img v-bind:src="carousel.pc_image_file_url" alt="">
          </figure>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  props: {
    carousels: null,
  },
}
</script>
