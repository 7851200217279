<template v-if="fetch_data">
  <section id="how_to" class="top_cts acc_menu">
    <Accordion title="複眼経済塾のはじめかた" storageKey="how_to_list">
      <div v-html="how_to_list" />
    </Accordion>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue';
export default {
    props: {
        how_to_list: null
    },
    components: { Accordion }
}
</script>
