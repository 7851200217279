<template>
  <section id="office_cts" class="top_cts acc_menu">
    <Accordion title="講義一覧" storageKey="overview">
      <div class="office_cts_list main_cts flex">
        <div class="menu_information flex">
          <div class="rec"><span class="recommend">は必聴オススメ講義</span></div>
          <div class="class_tag"><p>ご覧いただける講義</p><span class="c01">本科プラスの方</span><span class="c02">本科プラス・本科の方</span><span
              class="c03">本科プラス・本科・予科の方</span><span class="c04">学生科の方</span></div>
        </div>
        <template v-for="space in spaces.navi_categories.filter(space => space.rooms.length > 1)">
          <div class="office_cts_box" :key="space.id">
            <div class="head flex">
              <div class="icon">
                <img v-bind:src="space.image_file_url" alt="" class="menu_icon">
              </div>
              <div class="ttl">
                <h3>{{ space.title }}</h3>
                <p>{{ space.description }}</p>
              </div>
            </div>
            <ul class="link_list flex">
              <li v-for="room in space.rooms" :key="room.id"
                  v-bind:class="{plus_menu: room.only_regular_plus, recommend: room.navi_recommend, student_menu: room.rank_student}">
                <router-link @click.native="closeAllMenu()"
                             v-bind:class="{disabled: !room.readable}"
                             :to="{ name: 'SnsSpace', params: {nav_category: 'content', space_id: room.sns_space_id, room_id: room.id}}">
                  {{ room.title }}
                </router-link>

                <!-- TODO： 上原さん -->
                <div class="badge_type">
                  <span v-bind:class="'type_' + ranksClass(room)">{{ ranksString(room) }}</span>
                </div>

                <div class="badge" v-if="false">
                  <span class="c01" v-if="room.rank_regular_plus"></span><!--本科プラス-->
                  <span class="c02" v-if="room.rank_regular || room.rank_regular_default"></span><!--本科-->
                  <span class="c03" v-if="room.rank_preparatory"></span><!--予科-->
                  <span class="c04" v-if="room.rank_student"></span><!--学生-->
                </div>
              </li>
            </ul>
          </div>
        </template>
      </div>

      <div class="office_cts_list flex"
           v-if="spaces.navi_categories.filter(space => space.rooms.length === 1).length > 0">
        <template v-for="space in spaces.navi_categories.filter(space => space.rooms.length === 1)">
          <div class="office_cts_box" :key="space.id">
            <div class="head flex">
              <div class="icon">
                <img v-bind:src="space.image_file_url" alt="" class="menu_icon">
              </div>
              <div class="ttl">
                <router-link v-for="room in space.rooms" :key="room.id"
                             @click.native="closeAllMenu()"
                             v-bind:class="{disabled: !room.readable}"
                             :to="{ name: 'SnsSpace', params: {nav_category: 'content', space_id: room.sns_space_id, room_id: room.id}}">
                  <h3>{{ space.title }}</h3>
                </router-link>
              </div>
            </div>

            <ul class="link_list flex">
              <li v-for="room in space.rooms" :key="room.id"
                  v-bind:class="{plus_menu: room.only_regular_plus, recommend: room.navi_recommend, student_menu: room.rank_student}">
                <router-link @click.native="closeAllMenu()"
                             v-bind:class="{disabled: !room.readable}"
                             :to="{ name: 'SnsSpace', params: {nav_category: 'content', space_id: room.sns_space_id, room_id: room.id}}">
                  {{ room.title }}
                </router-link>

                <!-- TODO： 上原さん -->
                <div class="badge_type">
                  <span v-bind:class="'type_' + ranksClass(room)">{{ ranksString(room) }}</span>
                </div>

                <div class="badge" v-if="false">
                  <span class="c01" v-if="room.rank_regular_plus"></span><!--本科プラス-->
                  <span class="c02" v-if="room.rank_regular || room.rank_regular_default"></span><!--本科-->
                  <span class="c03" v-if="room.rank_preparatory"></span><!--予科-->
                  <span class="c04" v-if="room.rank_student"></span><!--学生-->
                </div>
              </li>
            </ul>

          </div>
        </template>
      </div>

      <div class="office_cts_list flex">
        <div class="office_cts_box" v-for="space in spaces.other_spaces" :key="space.id">
          <div class="head flex">
            <div class="icon">
              <img v-bind:src="space.image_file_url" alt="" class="menu_icon">
            </div>
            <div class="ttl">
              <router-link v-bind:class="{disabled: !space.rooms[0].readable}"
                           @click.native="closeAllMenu()"
                           :to="{ name: 'Sn' +
                              'sSpace', params: {nav_category: 'content', space_id: space.id, room_id: space.rooms[0].id}}">
                <h3>{{ space.rooms[0].title }}</h3>

                <!-- TODO： 上原さん -->
                <div class="badge_type">
                  <span v-bind:class="'type_' + ranksClass(space.rooms[0])">{{ ranksString(space.rooms[0]) }}</span>
                </div>

                <div class="badge" v-if="false">
                  <span class="c01" v-if="space.rooms[0].rank_regular_plus"></span><!--本科プラス-->
                  <span class="c02" v-if="space.rooms[0].rank_regular || room.rank_regular_default"></span><!--本科-->
                  <span class="c03" v-if="space.rooms[0].rank_preparatory"></span><!--予科-->
                  <span class="c04" v-if="space.rooms[0].rank_student"></span><!--学生-->
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="office_cts_box">
          <router-link :to="{ name: 'Archives'}" class="archives_btn"><span>全講義検索</span></router-link>
        </div>
      </div>
    </Accordion>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue';

export default {
  props: {
    spaces: null,
  },
  components: {Accordion},
  methods: {
    ranksString(room) {
      if (room.only_regular_plus) {
        return '本科プラス限定'
      } else if (room.only_student) {
        return '学生科限定'
      } else {
        let ranks = []
        if (room.rank_regular_plus) ranks.push('本科プラス')
        if (room.rank_regular || room.rank_regular_default) ranks.push('本科')
        if (room.rank_preparatory) ranks.push('予科')
        return ranks.join('・')
      }
    },
    ranksClass(room) {
      if (room.only_regular_plus) {
        return 'only_regular_plus'
      } else if (room.only_student) {
        return 'only_student'
      } else {
        let ranks = []
        if (room.rank_regular_plus) ranks.push('plus')
        if (room.rank_regular || room.rank_regular_default) ranks.push('regular')
        if (room.rank_preparatory) ranks.push('preparatory')
        if (room.rank_student) ranks.push('student')
        return ranks.join('_')
      }
    }
  }
}
</script>
