<template>
  <section id="work_shop" class="top_cts acc_menu">
    <Accordion title="ワークショップ" storageKey="workshop_list">
      <p class="top_lead_txt">
        複眼経済塾のメインとなるコンテンツです。<br>
        積極的なワークショップへの参加を通じて日経新聞、会社四季報への理解を高めましょう。
      </p>
      <div class="workshop_list flex">
        <div class="workshop_box">
          <h3 class="min_ttl">四季報10倍ワークショップ</h3>
          <div class="thumb"><img src="/common/images/top_workshop01.jpg" alt=""></div>
          <p>
            私たちが開発した分析手法である「四季報読破」のノウハウを皆さまにも使えるようになって頂句為に開催しているワークショップです。四季報を読み込む事で、様々なシーンで人とは一味違った仕事の展開ができるようになります。
          </p>
        </div>
        <div class="workshop_box">
          <h3 class="min_ttl">日経マジ読みワークショップ</h3>
          <div class="thumb"><img src="/common/images/top_workshop02.jpg" alt=""></div>
          <p>
            たかが日経新聞、されど日経新聞。この「マジ読み」ワークショップは、投資のための「足腰」を鍛える、あらゆる年代の方々から支持されているワークショップです。
            多くの塾生から、新聞の価値を見直したといった声を頂いています。
          </p>
        </div>
        <div class="workshop_box">
          <h3 class="min_ttl">四季報から潮目を読むワークショップ</h3>
          <div class="thumb"><img src="/common/images/top_workshop03.jpg" alt=""></div>
          <p>
            このワークショップは、四季報の最大の特徴である「コメント」をどう読み解くかについて教える講義です。渡部塾長がどのように四季報を読み解くのかをインタビュー形式で聞き出すとともに、その方法の一端を実際に体験いただきます。
          </p>
        </div>
        <div class="workshop_box">
          <h3 class="min_ttl">ポーカーから学ぶ投資術</h3>
          <div class="thumb"><img src="/common/images/top_workshop04.jpg" alt=""></div>
          <p>
            すべての投資において成功するための確率（オッズ）が必ず存在します。この確率を正しく把握することによって必要のない損失を避けることができます。このワークショップではポーカーを通じてこのメンタリティーを実践的に学びます。
          </p>
        </div>
      </div>
    </Accordion>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue';
export default {
    components: { Accordion }
}
</script>
