<template>
  <section id="activities" class="top_cts acc_menu">
    <div class="wrap">
      <h2 class="sub_ttl acc_tab" v-bind:class="{'open': open}"><span>複眼経済塾の活動 TODO:close</span></h2>
      <div class="contents acc_cts" style="display: block;">
        <ul class="activities_list">
          <li><a href="/media-info/"><img src="/common/images/top_bnr_activities01.jpg" alt="メディア"></a></li>
          <li><a href="/corporate_training/"><img src="/common/images/top_bnr_activities02.jpg" alt="企業研修"></a></li>
          <li><a href="/instructor/"><img src="/common/images/top_bnr_activities03.jpg" alt="講師派遣"></a></li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    open: null
  }
}
</script>
