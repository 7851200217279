<template>
  <section id="online_shop" class="top_cts acc_menu">
    <div class="wrap">
      <h2 class="sub_ttl acc_tab" v-bind:class="{'open': open}"><span>ショップ TODO:close</span></h2>
      <div class="contents acc_cts" style="display: block;">
        <div class="shop_item_list flex">
          <div class="item">
            <div class="thumb"><a href=""><img src="/common/images/sample/online_shop_item01.jpg" alt=""></a>
            </div>
            <p>米中新冷戦のはざまで 日本経済は必ず浮上する</p>
          </div>
          <div class="item">
            <div class="thumb"><a href=""><img src="/common/images/sample/online_shop_item02.jpg" alt=""></a>
            </div>
            <p>新キャッシュレス時代 日本経済が再び世界をリードする 世界はグロースからクオリティへ</p>
          </div>
          <div class="item">
            <div class="thumb"><a href=""><img src="/common/images/sample/online_shop_item03.jpg" alt=""></a>
            </div>
            <p>ウィズコロナ 日本株にビッグウェーブがやって来る!</p>
          </div>
        </div>
        <div class="button_wrap">
          <a href="#" class="submit basic arrow">ショップ</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    open: null
  }
}
</script>
