<template>
  <section id="recommend_cts" class="top_cts acc_menu">
    <Accordion title="おすすめのコンテンツ" storageKey="content_recommend">
      <ul class="recommend_list flex">

        <li v-for="post in posts" :key="'recomend-' + post.id">
          <router-link :to="{ name: 'SnsPost', params: {post_id: post.id}}" v-bind:class="{disabled: !post.readable}">
            <img v-bind:src="post.recommend_image_url" alt="">
            <span>{{ post.recommend_text }}</span>
          </router-link>
        </li>
      </ul>
      <div class="button_wrap">
        <router-link :to="{ name: 'SnsPostRecommends'}" class="submit basic arrow">
          おすすめのコンテンツを見る
        </router-link>
      </div>
    </Accordion>
  </section>
</template>

<script>
import Accordion from '../../../../../components/Accordion.vue';
export default {
    props: {
        posts: null
    },
    components: { Accordion }
}
</script>

<style>
a.disabled {
  opacity: 0.5;
  pointer-events: none !important;
  cursor: none !important;
}

</style>
