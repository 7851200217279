<template>
  <section v-bind:id="category + '_cts'" class="top_cts acc_menu">
    <Accordion :title="category_info[category].title" :storageKey="`category_${category}`">
      <SocialSpaces :spaces="category_data.spaces" v-if="category_data && category === 'social'"/>
      <template v-if="category === 'timeline' && category_data && category_data.posts.length === 0 && category_data.recommends.length > 0">
        <div class="cts_box_2">
          <h3 class="min_ttl">フォローのおすすめ</h3>
          <p class="lead_txt">あなたと共通する話題をお持ちの塾生です。こちらの方々もフォローしてみてはいかがでしょうか？</p>
          <div class="follow_list flex">
            <div class="follow_box" v-for="user in category_data.recommends" :key="user.id">
              <div class="icon">
                <router-link :to="{ name: 'SnsTimeline', params: {user_id: user.id}}">
                  <img v-bind:src="user.profile_image_url" alt="">
                </router-link>
              </div>
              <h3>{{ user.sns_user_name }}</h3>
              <a href="javascript:void(0)" class="btn_follow" @click="follow(user)" v-bind:class="{disabled: user.follow}">{{ user.follow ? 'フォロー済み' : 'フォローする' }}</a>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <p class="top_lead_txt" v-html="category_info[category].description"></p>
        <div class="cts_box" v-show="category_data && category_data.posts.length === 0">
          一致する投稿は見つかりませんでした。
        </div>
      </template>
      <div class="sort flex" v-show="category_data && category_data.posts.length">
        <p>並び替え</p>
        <select v-model="order" v-on:change="pageChanged(1)">
          <option value="">更新順</option>
          <option value="published_at_desc">投稿の新しい順</option>
          <option value="published_at_asc">投稿の古い順</option>
        </select>
      </div>

      <!--5件ごとのページ切り替え-->

      <!--フィード1セット-->
      <template v-if="category_data">
        <div class="feed_box" v-for="(post, key) in category_data.posts" :key="key" v-bind:id="'post-' + post.id"
             v-bind:class="post.status">
          <!-- post menu -->
          <SnsPostMenu :post="post" :show_permalink_menu="true" @editPost="editPost" @reportPost="reportPost"
                       @deletePost="deletePost" @hidePost="hidePost"
                       @playedTest="playedTest" @audioPlayedTest="audioPlayedTest"
          />

          <div class="user_info flex">
            <div class="icon achievement" v-bind:class="post.user.dan">
              <router-link
                  :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                <img v-bind:src="post.user.profile_image_url" alt="">
              </router-link>
            </div>
            <div class="detail">
              <div class="user_name">
                <router-link :to="{ name: 'SnsTimeline', params: {user_id: post.user.id}}">
                  {{ post.user.sns_user_name }}
                </router-link>
              </div>
              <div class="post_day">{{ moment(post.published_at, 'YYYY/MM/DD HH:mm') }}</div>
              <div class="favorite" v-bind:class="{active: post.favorite}">
                <a href="javascript:void(0)" class="flex" @click="favoritePost(post)">
                  <img v-bind:src="`/common/images/icon/star_favorite_${post.favorite}.svg`" alt="お気に入り">
                  <p>お気に入り</p>
                </a>
              </div>
            </div>
            <div class="hashtag" v-if="post.sns_tag">
              <router-link :to="{name: 'SnsSearch', query: {query: tag.substring(1)}}" v-for="tag in post.sns_tag.split(' ')" :key=tag>
                {{ tag }}
              </router-link>
            </div>
          </div>
          <div class="tag" v-if="post.space_title">
            <router-link :to="space_url(post)">{{ post.space_title }}</router-link>
          </div>
          <div class="post_title" v-if="post.title && post.title.length > 0">
            {{ post.title }}
          </div>
          <div class="post_body ql-container ql-snow" v-if="!post.edit_mode" v-bind:id="'post-body-' + post.id"
               v-bind:class="{height_rimit: (post.height_limit === 1 || post.height_limit === 2), open: post.height_limit === 2}">
            <div class="inner">
              <div class="post_body ql-editor" v-html="bodyEssence(post.body)"></div>
              <!-- ogp tag -->
              <SnsOgpTags :ogp_tags="post.ogp_tags"/>
              <!-- hedge txt -->
              <SnsPostHedgeText v-if="post.user.dan === 'rank_staff'" />
            </div>
            <div class="post_more" @click="post.height_limit = post.height_limit == 1 ? 2 : 1"><span></span></div>
          </div>

          <transition>
            <div class="cts_box" v-if="post.edit_mode">
              <div class="acc_menu post_form post_form_edit">
                <h3 class="min_ttl acc_tab" v-bind:class="{'open': post.edit_mode}" v-on:click="editPost(post)"><span>投稿内容の編集</span>
                </h3>
                <SnsPostForm :post="post" :tags="tags" v-on:saved="savePost"/>
              </div>
            </div>
          </transition>

          <div v-if="!post.edit_mode">
            <!-- post files -->
            <SnsFiles :files="post.files"/>

            <div class="post_info flex">
              <div class="nice_box flex">
                <div class="nice" v-on:mouseover="likeOver(post)" v-on:mouseleave="likeLeave(post)"
                     @click="likeClick(post)"><span>{{ delimited(post.like_user_count) }}</span></div>
                <div class="btn_nice" v-bind:class="{active: post.like_user}" v-on:click="likeUserPost(post)">
                  <span>いいね！</span>
                </div>
                <SnsLikeUserList :post="post" v-if="post.show_like_list"/>
                <SnsLikeUserModal :post="post" v-if="post.show_like_modal" @close="likeModalClose(post)"
                                  @follow="follow" @un_follow="unFollow"/>
              </div>
              <div class="comment">コメント<span>{{ delimited(post.comment_count) }}</span>件</div>
            </div>
            <div class="comment_more" v-show="post.comments.length < post.comment_count"
                 v-on:click="fetchComments(post)">以前のコメントを全て見る（<span>{{ delimited(post.comment_count) }}</span>件）
            </div>

            <!--コメント1セット-->
            <div v-if="post.comment_addable">
              <div class="comment_body flex" v-for="(comment, key) in post.comments" :key="key"
                   v-bind:id="'comment-' + comment.id">
                <div class="icon achievement" v-bind:class="comment.user.dan">
                  <router-link
                      :to="{ name: 'SnsTimeline', params: {user_id: comment.user.id}}">
                    <img v-bind:src="comment.user.profile_image_url" alt="">
                  </router-link>
                </div>
                <div class="comment_post_box">
                  <div class="inner">
                    <!-- comment menu -->
                    <SnsCommentMenu :comment="comment" @editComment="editComment(comment)"
                                    @reportComment="reportComment(post, comment)"
                                    @deleteComment="deleteComment(post, comment)"/>

                    <div class="head flex">
                      <div class="user_name">
                        <router-link :to="{ name: 'SnsTimeline', params: {user_id: comment.user.id}}">
                          {{ comment.user.sns_user_name }}
                        </router-link>
                      </div>
                      <div class="post_day">{{ moment(comment.updated_at, 'YYYY/MM/DD HH:mm') }}</div>
                    </div>
                    <div class="comment_post_body ql-container ql-snow" v-if="!comment.edit_mode">
                      <div class="post_body ql-editor" v-html="bodyEssence(comment.body)"></div>
                    </div>

                    <transition>
                      <SnsPostCommentForm :post="post" :comment="comment" v-if="comment.edit_mode"
                                          v-on:saved="saveComment"/>
                    </transition>
                  </div>

                  <div v-if="!comment.edit_mode">
                    <SnsFiles :files="comment.files"/>
                    <div class="comment_info flex">
                      <div class="comment_nice" v-bind:class="{active: comment.like_user}"
                           v-on:click="likeUserComment(comment)">いいね！
                      </div>
                      <div class="count" v-on:mouseover="commentLikeOver(comment)"
                           v-on:mouseleave="commentLikeLeave(comment)" @click="commentLikeClick(comment)">
                        （<span>{{ delimited(comment.like_user_count) }}</span>）
                      </div>
                      <SnsLikeUserList :comment="comment" v-if="comment.show_like_list"/>
                      <SnsLikeUserModal :comment="comment" v-if="comment.show_like_modal"
                                        @close="commentLikeModalClose(comment)" @follow="follow" @un_follow="unFollow"/>
                    </div>
                  </div>
                </div>
              </div>

              <!--コメント1セットここまで-->
              <div class="post_btn flex">
                <div class="btn_comment" v-if="post.comment_addable"
                     v-on:click="togglePostCommentForm(post)">
                  <span>コメントする</span></div>
              </div>
            </div>

            <transition>
              <SnsPostCommentForm :post="post" :comment="new_comment"
                                  v-if="new_comment.edit_mode && new_comment.sns_post_id === post.id"
                                  v-on:saved="newComment"/>
            </transition>
          </div>
        </div>
      </template>
      <!--pager-->
      <Pagination v-if="category_data" :pagination="category_data.pagination" v-on:changed="pageChanged"/>
      <!--//pager-->
    </Accordion>
  </section>
</template>

<script>

import Libraries from '@/components/Libraries.vue'
import SnsBaseCommons from '@/components/sns/SnsBaseCommons.vue'
import SnsOgpTags from '@/components/sns/SnsOgpTags.vue'
import SnsFiles from '@/components/sns/SnsFiles.vue'
import SnsPostForm from '@/components/sns/SnsPostForm.vue'
import SnsPostMenu from '@/components/sns/SnsPostMenu.vue'
import SnsPostCommentForm from '@/components/sns/SnsPostCommentForm.vue'
import SnsCommentMenu from '@/components/sns/SnsCommentMenu.vue'
import Pagination from '@/components/Pagination.vue'
import SocialSpaces from '@/views/users/customer/elements/top/_social_spaces.vue'
import Accordion from '@/components/Accordion'
import SnsLikeUserList from "@/components/sns/SnsLikeUserList";
import SnsLikeUserModal from '@/components/sns/SnsLikeUserModal'
import SnsPostHedgeText from "@/components/sns/SnsPostHedgeText.vue";

export default {
  mixins: [Libraries, SnsBaseCommons],
  components: {
    SnsPostHedgeText,
    SnsOgpTags,
    SnsFiles,
    SnsPostForm,
    SnsPostMenu,
    SnsPostCommentForm,
    SnsCommentMenu,
    SnsLikeUserList,
    SnsLikeUserModal,
    Pagination,
    SocialSpaces,
    Accordion,
  },
  data() {
    return {
      category_info: {
        timeline: {
          title: '複眼SNS',
          description: '現在フォロー中の個人のタイムラインを表示しています。塾生同士の交流や日常の何気ない出来事の共有などにご利用ください。'
        },
        content: {
          title: '最新講義のタイムライン',
          description: '直近アップロードされた各講義を、更新順に表示しています。「投稿の新しい順」「投稿の古い順」に並べ替えることもできます。' +
              '各講義をクリック頂くと、詳細な内容をご覧いただけます。講義には、種類によって、動画があるもの・無いものがあります。' +
              'また、資料があるもの・無いもの、があります。動画の視聴推奨環境は<a href="/rule/#no5">コチラ</a>をご確認下さい。資料は原則PDFでダウンロード頂けます。'
        },
        notice: {
          title: '事務局通信のタイムライン',
          description: '複眼経済塾事務局から発信するお知らせ・ご案内を掲載しています。重要なお知らせの他、月例会やワークショップ、合宿等のご案内が入ります。該当箇所をクリック頂くと、詳細なご案内が出てきます。'
        },
        social: {
          title: 'テーマ別グループのタイムライン',
          description: ''
        },
        workshop: {
          title: 'イベント参加者専用スペースのタイムライン',
          description: '過去に参加した研修会のSNSに投稿された内容を表示しています。'
        },
        media: {
          title: 'メディアのタイムライン',
          description: 'メディア各種インフォメーションに投稿された内容を表示しています。'
        },
      },
      order: '',
      category_data: null,
    }
  },
  props: {
    tags: null,
    category: null,
  },
  methods: {
    // ページネーション
    pageChanged(page) {
      this.axios
          .get(`${this.env.api_base_url}sns/posts_category.json`, {
            params: {
              category: this.category,
              page: page,
              order: this.order,
              limit: 10
            },
          })
          .then(response => {
            this.addFrontParams(response.data)
            const data = response.data
            this.category_data.posts = data.posts
            this.category_data.pagination = data.pagination
            this.scrollTo(`#${this.category}_cts`, 10)
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            // 投稿の要素の高さを調べる
            this.checkPostBodyHeight(this.category_data.posts)
            // this.finishAjax()
          })
    },
    // フォロ
    follow(user) {
      this.axios
          .post(`${this.env.api_base_url}sns/follow.json`, {
            user_id: user.id
          })
          .then(() => {
            user.follow = true
            // if (reload) this.fetchData()
            // else this.fetch_reload = true
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            //this.finishAjax()
          })
    },
  },
  async created() {
    try {
      const response = await this.axios.get(
          `${this.env.api_base_url}users/category/${this.category}.json`, {
            params: {
              limit: 10
            }
          }
      )
      this.addFrontParams(response.data)

      this.category_data = response.data
      setTimeout(() => {
        // 投稿の要素の高さを調べる
        this.checkPostBodyHeight(this.category_data.posts)
      }, 0)
    } catch (e) {
      this.errorAjax(e)
    }
  }
}
</script>
