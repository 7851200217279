<template>
  <section id="activities" class="top_cts acc_menu">
    <Accordion title="複眼経済塾の活動" storageKey="activities">
      <ul class="activities_list">
        <li><a href="/media-info/"><img src="/common/images/top_bnr_activities01.jpg" alt="メディア"></a></li>
        <li><a href="/corporate_training/"><img src="/common/images/top_bnr_activities02.jpg" alt="企業研修"></a></li>
        <li><a href="/instructor/"><img src="/common/images/top_bnr_activities03.jpg" alt="講師派遣"></a></li>
      </ul>
    </Accordion>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'

export default {
  components: { Accordion },
}
</script>
