<template>
  <div v-if="pagination.total_pages > 1">
    <div class="pager flex" v-if="pagination.total_pages <= range">
      <div class="btn prev">
        <a href="javascript:void(0)" v-if="pagination.prev_page"
           v-on:click="$emit('changed', pagination.current_page - 1)"></a>
      </div>
      <div class="btn" v-for="(page, key) in pagination.total_pages" :key="key"
           v-bind:class="{current: page === pagination.current_page}">
        <a href="javascript:void(0)" v-on:click="$emit('changed', page)">{{ page }}</a>
      </div>
      <div class="btn next">
        <a href="javascript:void(0)" v-if="pagination.next_page"
           v-on:click="$emit('changed', pagination.current_page + 1)"></a>
      </div>
    </div>

    <div class="pager flex" v-if="pagination.total_pages > range">
      <div class="btn prev">
        <a href="javascript:void(0)" v-if="pagination.prev_page"
           v-on:click="$emit('changed', pagination.current_page - 1)"></a>
      </div>

      <div class="btn" v-if="start() > 1">
        <a href="javascript:void(0)" v-on:click="$emit('changed', 1)">1</a>
      </div>
      <div class="btn dotted" v-if="start() > 1"></div>

      <div class="btn" v-for="(page, key) in range" :key="key"
           v-bind:class="{current: (page + start()) === pagination.current_page}">
        <a href="javascript:void(0)" v-on:click="$emit('changed', page + start())">{{ (page + start()) }}</a>
      </div>

      <div class="btn dotted" v-if="start() + range + 1 < pagination.total_pages"></div>
      <div class="btn" v-if="start() + range + 1 < pagination.total_pages">
        <a href="javascript:void(0)"
           v-on:click="$emit('changed', pagination.total_pages)">{{ pagination.total_pages }}</a>
      </div>

      <div class="btn next">
        <a href="javascript:void(0)" v-if="pagination.next_page"
           v-on:click="$emit('changed', pagination.current_page + 1)"></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      range: 5,
      middle: 2,
    }
  },
  props: {
    pagination: null
  },
  methods: {
    start() {
      const current_page = this.pagination.current_page
      const total_pages = this.pagination.total_pages
      let start_page = 1
      if (current_page <= this.middle) {
        start_page = 1
      } else if (current_page + this.middle >= total_pages) {
        start_page = total_pages - this.range + 1;
      } else {
        start_page = current_page - this.middle;
      }
      return start_page - 1
    }
  }
}
</script>
