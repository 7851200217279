<template>
  <section id="event" class="top_cts acc_menu">
    <Accordion title="イベントのスケジュール一覧" storageKey="event_list">
      <p class="top_lead_txt">
        「月例会（現地講義）」「ワークショップ（教室・オンライン）」「複眼合宿」「複眼ツアー」等の各研修会に関してのご案内です。<br>
        お申込みは、該当の日時の箇所をクリック頂くと、各研修会の詳細なご案内と、申込画面が出てきます。各クラス（本科プラス、本科、予科）によって、受講できる研修会が異なります。<br>
        また各価格についてもクラス別に異なる物がありますので予めご了承下さい。
      </p>
      <ul class="event_list">
        <li class="flex" v-for="(model, key) in all_events" :key="key">
          <template v-if="model.category">
            <div class="info">
              <div class="class">
                <span class="tag class01" v-if="model.rank_regular_plus">本科プラス</span>
                <span class="tag class02" v-if="model.rank_regular">本科</span>
                <span class="tag class03" v-if="model.rank_preparatory">予科</span>
                <span class="tag class04" v-if="model.rank_general">一般</span>
                <span class="tag class05" v-if="model.rank_student">学生科</span>
                <span class="tag class06" v-if="false /*model.rank_corporation*/">法人科</span>
              </div>

              <h3>{{ model.title }}</h3>
              <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD') }}</span><span class="time">{{
                  model.open_time
                }}</span></div>
              <p v-html="model.list_text"></p>
              <div v-if="model.category === 'camp_tour' || model.category === 'camp_camp'">
                <div class="detail flex"><span class="tag">開催地</span>{{ model.place }}</div>
              </div>
              <div v-else>
                <div class="detail flex" v-if="model.offline"><span class="tag">教室</span>{{ model.place }}</div>
                <div class="detail flex" v-if="model.online"><span class="tag red">LIVE</span>オンライン</div>
              </div>
            </div>
            <div class="btn">
              <router-link :to="{ name: 'WorkshopShow', params: {category: model.category, id: model.id} }"
                           class="linkBtn light">研修会詳細
              </router-link>
            </div>
          </template>
          <template v-else>
            <div class="info">
              <div class="class">
                <span class="tag class01" v-if="model.rank_regular_plus">本科プラス</span>
                <span class="tag class02" v-if="model.rank_regular">本科</span>
                <span class="tag class03" v-if="model.rank_preparatory">予科</span>
                <span class="tag class04" v-if="model.rank_general">一般</span>
                <span class="tag class05" v-if="model.rank_student">学生科</span>
                <span class="tag class06" v-if="false /*model.rank_corporation*/">法人科</span>
              </div>
              <h3>{{ model.title }}</h3>
              <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD') }}</span><span
                  class="time">{{ model.open_time }}</span></div>
              <div class="detail flex">
                <template v-if="model.held_type === 'held_type_temporary'">
                  <span class="tag">開催地</span>{{ model.relay_place }}
                </template>
                <template v-else>
                  <span class="tag">中継会場</span>{{ model.relay_place }}
                </template>
              </div>
              <p v-html="model.list_text"></p>
            </div>
            <div class="btn">
              <router-link :to="{ name: 'GetsureiShow', params: {id: model.id} }"
                           class="linkBtn light">研修会詳細
              </router-link>
            </div>
          </template>
        </li>
      </ul>
      <div class="button_wrap">
        <router-link :to="{name: 'UsersCustomerJoinEvents'}" class="submit basic arrow">研修会スケジュール</router-link>
      </div>
    </Accordion>
  </section>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Accordion from '@/components/Accordion.vue'

export default {
  mixins: [Libraries],
  components: {Accordion},
  props: {
    events: null,
  },
  data() {
    return {
      all_events: null,
    }
  },
  mounted() {
    // 研修会と月例会を時系列でまとめる
    this.all_events = this.events.workshops
    this.all_events = this.all_events.concat(this.events.monthly_lives)
    this.all_events.sort((a, b) => {
      if (a.open_date < b.open_date) return -1
      if (a.open_date > b.open_date) return 1
      return 0
    })
  }
}
</script>
