<template>
  <div class="room_list flex">
    <div class="room_box flex" v-for="(space, key) in spaces" :key="key" v-bind:class="{'active': space.user_join}">
      <router-link :to="{ name: 'SnsSpace', params: {nav_category: 'social', space_id: space.id, room_id: 0}}" class="flex">
        <div class="icon">
          <img v-bind:src="space.image_file_url" alt="">
        </div>
        <div class="txt_cts">
          <h3>{{ space.title }}</h3>
          <p>{{ space.description_text.substring(0, 42) }}</p>
        </div>
      </router-link>
    </div>
    <div class="button_wrap mt20">
      <a href="https://millioneyescoltd.my.webex.com/millioneyescoltd.my/j.php?MTID=m4fe8ca2cd74ef7704cebeba1d5199a7d" class="submit light chat" target="_blank"><i>複眼自由会議室</i></a>
    </div>
    <div class="button_wrap">
      <router-link :to="{name:'SnsSocials'}" class="submit basic arrow">テーマ別グループ一覧</router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    spaces: null
  }
}
</script>
