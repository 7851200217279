<template>
  <div class="wrap">
    <h2 class="sub_ttl" :class="{ open }" @click.stop.prevent="toggle">
      <span>{{ title }}</span>
    </h2>
    <SlideUpDown :active="open">
      <div class="contents">
        <slot />
      </div>
    </SlideUpDown>
  </div>
</template>

<script>
import SlideUpDown from 'vue-slide-up-down'

export default {
  components: { SlideUpDown },
  props: {
    title: { type: String, required: true },
    storageKey: { type: String, default: null },
    defaultOpen: { type: Boolean, default: true },
  },
  data() {
    return {
      open: true,
    }
  },
  methods: {
    toggle() {
      this.open = !this.open
      if (this.storageKey) localStorage[`accordion_${this.storageKey}`] = this.open.toString()
    },
  },
  created() {
    this.open = this.defaultOpen
    if (this.storageKey) {
      const stored = localStorage[`accordion_${this.storageKey}`]
      if (stored) this.open = stored === 'true'
    }
  }
}
</script>

<style scoped>
.sub_ttl {
  margin: 0;
  padding-right: 45px;
  position: relative;
  cursor: pointer;
}
.sub_ttl:after {
  content: "";
  width: 30px;
  height: 36px;
  background: url("../assets/img/top_acc_open.svg") no-repeat center / contain;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0,-50%);
}
.sub_ttl.open:after {
  background: url("../assets/img/top_acc_close.svg") no-repeat center / contain;
}
.contents {
  padding: 30px 0 0 0;
}
</style>
