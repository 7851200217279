<template>
  <section id="today_event" class="top_cts acc_menu">
    <Accordion title="本日開催の研修会" storageKey="event_today">
      <ul class="event_list" v-if="today.workshops.length || today.monthly_lives.length">
        <li class="flex" v-for="model in today.workshops" :key="model.id">
          <div class="info">
            <h3>{{ model.title }}</h3>
            <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD') }}</span><span class="time">{{ model.open_time }}</span></div>
            <div v-if="model.category === 'camp_tour' || model.category === 'camp_camp'">
              <div class="detail flex"><span class="tag">開催地</span>{{ model.place }}</div>
            </div>
            <div v-else>
              <div class="detail flex" v-if="model.offline"><span class="tag">教室</span>{{ model.place }}</div>
              <div class="detail flex" v-if="model.online"><span class="tag red">LIVE</span>オンライン</div>
            </div>
          </div>
          <div class="btn">
            <router-link :to="{ name: 'WorkshopShow', params: {category: model.category, id: model.id} }"
                          class="linkBtn light">研修会詳細
            </router-link>
          </div>
        </li>

        <li class="flex" v-for="model in today.monthly_lives" :key="model.id">
          <div class="info">
            <h3>{{ model.title }}</h3>
            <div class="date"><span class="day">{{ moment(model.open_date, 'YYYY/MM/DD') }}</span><span class="time">{{ model.open_time }}</span></div>
            <div class="detail flex">
              <template v-if="model.held_type === 'held_type_temporary'">
                <span class="tag">開催地</span>{{ model.relay_place }}
              </template>
              <template v-else>
                <span class="tag">中継会場</span>{{ model.relay_place }}
              </template>
            </div>
          </div>
          <div class="btn">
            <router-link :to="{ name: 'GetsureiShow', params: {id: model.id} }"
                          class="linkBtn light">研修会詳細
            </router-link>
          </div>
        </li>
      </ul>
      <p v-else>本日開催の研修会はありません。</p>
    </Accordion>
  </section>
</template>

<script>
import Libraries from '@/components/Libraries.vue'
import Accordion from '@/components/Accordion.vue'

export default {
    mixins: [Libraries],
    props: {
        today: null,
    },
    components: { Accordion }
}
</script>
